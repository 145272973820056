var winWidth = $(window).width(),
    winHeight = $(window).height();
var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);

ChangeToSvg();
// addOnLoadAnimation();

$("#LoadingBar").stop().animate({
    width: "25%"
}, 100);
setTimeout(function(){
    if(parseInt($("#LoadingBar").css('width').split('%')[0]) <= 25) {
        $("#LoadingBar").stop().animate({
            width: "60%"
        }, 500);
    }
},500);


function addOnLoadAnimation(){		
	setTimeout(function () {		
		$('.bricks .slide--1').addClass('is--active');		
	}, 1500);		
	if(isIE11 == false) {
		if(winWidth>=1024){		
			// Fade In Left
			$('.step-nav ul, .c-sec-school .c-information-form.information--form, .c-page-body.contentPage .contactDetail-info > .o-container > .c-row > *, .c-banner-image-wrapper .c-banner-figure .figure-item img, .c-banner-image-wrapper .c-banner-figure .figure-item, .c-sec-calender .c-thumbnailBox.c--horizontal .thumbContent, .c-tickets-detail .slick-slide, .c-sec-tickets > .o-container:first-child .sliderArrow, .upper-img-daily-event .sliderArrow, .c-tab-info .nav-tabs li, .c-img-swipper, .c-img-swipper .img-swipper img, .c-timeline .timeline-carousel .slick-slide > div, .sec-plan-your-visit .c-context-listing-wrap, .sec-plan-your-visit .c-heading-context, .c-image-wrapper img, .c-image-wrapper, .sec-gallery-grid .deco-div, .sec-tabber-wrap .deco-bor, .c-home-banner-image-wrapper .c-home-banner-figure .figure-item .slick-slider, .c-home-banner-image-wrapper .c-home-banner-figure .figure-item').attr('data-aos', 'fade-left');
			// Fade In Right		
			$('.step-nav ul li, .c-event-banner .event-txt-box, .sec-faqs .c-row:first-child p, .sec-faqs .c-row:first-child .c-heading, .c-sec-school .school-left-col, .c-sec-listing.c--media-listing .c-plan-download .deco-bor, .c-sec-listing.c--media-listing > .o-container > .c-row:first-child .c-heading + p, .c-sec-listing.c--media-listing > .o-container > .c-row:first-child .c-heading, .c-home-banner.sec-contact .c-banner-content-wrapper, .c-sec-calender .c-thumbnailBox.c--horizontal .thumbImg, .c-sec-calender .calender-page-header .c-heading.style--1, .c-sec-tickets .c-tickets-indicator, .c-sec-tickets .c-heading-context p, .c-sec-tickets .c-heading-context h2, .c-transform-text, .c-heading-context.context--discover, .c-real-footer > .c-row > div[class^="c-col"], .c-tab-info .nav-tabs + .tab-content, .c-home-banner-content-wrapper .c-home-banner-caption, .c-home-banner-figure-caption .js-figureCaptionSlider').attr('data-aos', 'fade-right');		
			// Fade In Up		
			$('.content-404, .c-booking-confirmation .contentsocialMedia ul, .c-booking-confirmation .confirmationDetail, #cmaForm .c-stepForm-btn, #cmaForm, .c-sec-three-holder .c-heading-context, .sec-faqs  .faq-content > *, .sec-faqs  .c-side-nav > *, .sec-privacy, .sec-termsCondition, .c-plan-download li, .c-sec-listing .news-content > .cta-btn, .c-sec-listing .thumbnailBox > *, .c-sec-listing .c-side-nav > *, .c-sec-listing.media-article > .o-container > *, .mediacenter-loginbox .c-form, .mediacenter-loginbox .login-img, .mediacenter-loginbox .login-box, .carouselCover .eventsCarouselNav, .c-sec-calender .filterbyCategory, .c-sec-calender .filterSelected, .c-sec-calender .filterbydateBar, .c-text-slider .slick-slider, .c-text-slider .slickIndicator, .c-timeline, .c-sec-map .map-sec-part .map-tab-wrap, .c-sec-map, .c-sec-three-holder .c-upholder-wrap, .sec-daily-events, .c-sec-innerBanner, .footer-bottom, .c-gallery-grid, .c-carouselCover-btn, .carouselCover .c-thumbnailBox, .sec-head-content').attr('data-aos', 'fade-up');		
			// Delays		
			$('.c-tab-info .nav-tabs + .tab-content, .sec-tabber-wrap .deco-bor, .c-home-banner-content-wrapper .c-home-banner-caption').attr('data-aos-delay', 600);		
			$('.sec-plan-your-visit .c-context-listing-wrap, .c-home-banner-image-wrapper .c-home-banner-figure .figure-item .slick-slider').attr('data-aos-delay', 500);		
			$('.c-booking-confirmation .contentsocialMedia ul, .c-sec-tickets > .o-container:first-child .sliderArrow, .sec-plan-your-visit .c-heading-context').attr('data-aos-delay', 400);		
			$('.c-booking-confirmation .confirmationDetail, #cmaForm, .sec-faqs  .faq-content > *, .c-sec-calender .c-thumbnailBox.c--horizontal .thumbImg, .c-sec-calender .c-thumbnailBox.c--horizontal .thumbContent, .c-sec-tickets .c-tickets-indicator, .c-img-swipper, .upper-img-daily-event .sliderArrow, .c-home-banner-image-wrapper .c-home-banner-figure .figure-item').attr('data-aos-delay', 300);		
			$('.sec-faqs .c-row:first-child p, .c-sec-school .school-left-col, .c-sec-school .c-information-form.information--form, .c-sec-listing.c--media-listing .c-plan-download .deco-bor, .mediacenter-loginbox .c-form, .c-banner-image-wrapper .c-banner-figure .figure-item img, .c-sec-calender .filterbyCategory, .c-sec-tickets .c-heading-context p').attr('data-aos-delay', 200);		
			$('.sec-faqs .c-row:first-child .c-heading, .sec-privacy, .sec-termsCondition, .c-sec-listing.c--media-listing > .o-container > .c-row:first-child .c-heading + p, .c-sec-listing.c--media-listing > .o-container > .c-row:first-child .c-heading, .mediacenter-loginbox .login-box, .mediacenter-loginbox .login-img, .c-banner-image-wrapper .c-banner-figure .figure-item, .c-home-banner.sec-contact .c-banner-content-wrapper, .c-sec-calender .filterSelected, .c-sec-calender .calender-page-header .c-heading.style--1, .c-sec-tickets .c-heading-context h2, .footer-bottom, .c-image-wrapper img').attr('data-aos-delay', 100);		
					
			// Loops		
			$('.c-plan-download li').each(function (i) {		
				var count = i;		
				if(Math.abs(count % 2) == 1){		
					count = i-1;		
				}		
				var dealy = 100+(50*count);		
				if(dealy > 300){		
					dealy = 300;		
				}
				$(this).attr('data-aos-delay', dealy);		
			});		
			$('.c-sec-listing.media-article > .o-container > *').each(function (i) {		
				var dealy = 100+(50*i);		
				if(dealy > 400){		
					dealy = 400;		
				}		
				$(this).attr('data-aos-delay', dealy);		
			});		
			$('.step-nav ul li').each(function (i) {		
				$(this).attr('data-aos-delay', 100+(100*i));		
			});		
			$('.c-event-banner .event-txt-box').each(function (i) {		
				$(this).attr('data-aos-delay', 100+(100*i));		
			});		
			$('.sec-faqs  .c-side-nav > *').each(function (i) {		
				$(this).attr('data-aos-delay', 300+(100*i));		
			});		
			$('.c-page-body.contentPage .contactDetail-info > .o-container > .c-row > *').each(function (i) {		
				$(this).attr('data-aos-delay', 200+(100*i));		
			});		
			$('.c-tickets-detail .slick-slide').each(function (i) {		
				$(this).attr('data-aos-delay', 500+(100*i));		
			});		
			$('.c-img-swipper .img-swipper img').each(function (i) {		
				$(this).attr('data-aos-delay', 100+(100*i));		
			});		
			$('.c-timeline .timeline-carousel .slick-slide > div').each(function (i) {		
				$(this).attr('data-aos-delay', 500+(100*i));		
			});		
			$('.c-sec-three-holder .c-upholder-wrap').each(function (i) {		
				$(this).attr('data-aos-delay', 150*i);		
			});		
			$('.c-real-footer > .c-row > div[class^="c-col"]').each(function (i) {		
				$(this).attr('data-aos-delay', 100*i);		
			});
			$('.c-tab-info .nav-tabs li').each(function (i) {		
				$(this).attr('data-aos-delay', 600+(100*i));		
			});		
			// ========================		
			// CF		
			// Fade In Left		
			$('.c-event-filter-context, .sec-pvBanner .pvContentSide').attr('data-aos', 'fade-left');		
			// Fade In Right		
			$('.c-sec-content-header .u-pos-r, .c-sec-event-with-slider .c-event-filter-nav, .c-sec-event-with-slider .event-filter-img, .sec-pvBanner .c-context-listing-wrap .c-context-listing, .sec-pvBanner .pvImgSide, .c-sec-school .c-plan-download, .c-sec-school .c-school-content, .c-sec-listing.news--listing2 > .o-container > .c-row:first-child p, .c-sec-listing.news--listing2 > .o-container > .c-row:first-child .c-heading, .mediacenter-loginbox .deco-div').attr('data-aos', 'fade-right');		
			// Fade In Up		
			$('.c-thumbnailBox .thumbImg, .c-sec-news-article .o-container > *, .event--view-all-btn, .sec-upEvents .c-thumbnailBox').attr('data-aos', 'fade-up');		
			// Delays		
			// $('').attr('data-aos-delay', 600);		
			// $('').attr('data-aos-delay', 500);		
			// $('').attr('data-aos-delay', 400);		
			$('.mediacenter-loginbox .deco-div').attr('data-aos-delay', 300);		
			$('.c-sec-event-with-slider .c-event-filter-nav, .c-sec-listing.news--listing2 > .o-container > .c-row:first-child p').attr('data-aos-delay', 200);		
			$('.c-sec-content-header .u-pos-r, .c-sec-event-with-slider .event-filter-img, .sec-pvBanner .pvImgSide, .sec-pvBanner .pvContentSide, .c-sec-listing.news--listing2 > .o-container > .c-row:first-child .c-heading').attr('data-aos-delay', 100);		
					
			// Loops		
			$('.sec-upEvents .c-thumbnailBox').each(function (i) {		
				var count = i;		
				if(Math.abs(count % 2) == 1){		
					count = i-2;		
				}		
				var dealy = 100+(50*count);		
				if(dealy > 300){		
					dealy = 300;		
				}		
				$(this).attr('data-aos-delay', dealy);		
			});		
			$('.c-sec-news-article .o-container > *').each(function (i) {		
				var dealy = 100+(50*i);		
				if(dealy > 400){		
					dealy = 400;		
				}		
				$(this).attr('data-aos-delay', dealy);		
			});		
			$('.sec-pvBanner .c-context-listing-wrap .c-context-listing').each(function (i) {		
				$(this).attr('data-aos-delay', 100+(100*i));		
			});		
		}

		$('[data-aos]').each(function(){
			if($(this).offset().top < winHeight) {
				$(this).removeAttr('data-aos');
				$(this).removeAttr('data-aos-delay');
			}
		});

		AOS.init({
			duration: 600,
			once: !0,
			offset: 0
		});
	}else{
		if($('[rel="preload"][as="style"]').get(0)){
			var $styleLink = $('[rel="preload"][as="style"]');
			$styleLink.attr('rel', 'stylesheet');
		}
	}
}

function ChangeToSvg() {
	$('img.js-inline-svg').each(function () {
	    var $img = $(this);
	    var imgID = $img.attr('id');
	    var imgClass = $img.attr('class');
	    var imgURL = $img.attr('src');
	    $.get(imgURL, function (data) {
	      var $svg = $(data).find('svg');
	      if (typeof imgID !== 'undefined') {
	        $svg = $svg.attr('id', imgID);
	      }
	      if (typeof imgClass !== 'undefined') {
	        $svg = $svg.attr('class', imgClass + ' insvg');
	      }
	      $svg = $svg.removeAttr('xmlns:a');
	      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	      }
	      $img.replaceWith($svg);
	    }, 'xml');
	});
}